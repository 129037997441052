
import useUser from '@/composables/useUser';

enum EnabledExamRoutes {
  fromShare = 'SharedExamination',
  fromGenPdf = 'GeneratePdf'
}

import ExaminationFromShare from '@/components/Commons/Examination/ExaminationFromShare.vue';
import ExaminationFromGeneratePdf from '@/components/Commons/Examination/ExaminationFromGeneratePdf.vue';
import router from '@/router';
import { computed, defineComponent, ref } from 'vue';
import ExaminationFromGeneratePdfForAdmin from '@/components/Commons/Examination/ExaminationFromGeneratePdfForAdmin.vue';
export default defineComponent({
  name: 'ExaminationRouter',
  components: {
    ExaminationFromGeneratePdfForAdmin,
    ExaminationFromGeneratePdf,
    ExaminationFromShare
  },
  setup() {
    const routeName = router.currentRoute.value.name;
    const activeComponent = ref('');
    const isAdmin = computed(() => useUser().isAdmin());

    switch (routeName) {
      case EnabledExamRoutes.fromShare:
        activeComponent.value = EnabledExamRoutes.fromShare;
        break;
      case EnabledExamRoutes.fromGenPdf:
        activeComponent.value = EnabledExamRoutes.fromGenPdf;
        break;
    }

    return {
      activeComponent,
      EnabledExamRoutes,
      isAdmin
    };
  }
});
