<template>
  <CommonExamination
    :is-loading="isLoading"
    :study-list="studyList"
    :showPrint="true"
  />
</template>

<script lang="ts">
import { computed, ref, onMounted, defineComponent, watch } from 'vue';
import CommonExamination from '@/components/Commons/Examination/CommonExamination.vue';
import router from '@/router';
import useResource from '@/composables/useResource';
import useConsent from '@/composables/useConsent';
import useExamination from '@/components/Commons/Examination/useExamination';
import usePatient from '@/composables/usePatient';
import PatientAndAdmin from '@/components/PatientAndAdmin/PatientAndAdmin';

export default defineComponent({
  name: 'ExaminationFromGeneratePdf',
  components: {
    CommonExamination
  },
  setup() {
    const isLoading = ref<boolean>(true);
    const resourceType = router.currentRoute.value?.params?.resourceType?.toString();
    const resourceId = router.currentRoute.value?.params?.resourceId?.toString();
    const studyList = computed(() => useExamination().getStudyList());
    const patientAndAdminResources = { ...PatientAndAdmin() };

    onMounted(async () => {
      if (resourceType && resourceId) {
        await useConsent().loadListConsentsSharedForUser();
      }

      isLoading.value = false;
    });

    const sharedFourUser = computed(() =>
      useConsent().getListConsentsSharedForUser()
    );

    watch(
      sharedFourUser,
      async list => {
        const consents = await useResource().findResourceInListByTypeAndId(
          list,
          resourceType,
          resourceId
        );
        if (!consents.length) {
          const patients = computed(() => usePatient().getPatients());
          const resource = await useResource().loadResourceByTypeAndId(
            resourceType,
            resourceId
          );
          const resourcePatientReference = await usePatient().loadPatientByReference(
            patientAndAdminResources.selectedTenant.value,
            resource.subject.reference
          );
          const validPatient = !!patients.value.find(
            patient => patient.pesel === resourcePatientReference.pesel.value
          );
          if (validPatient) useExamination().mapStudyList([{ resource }]);
        } else {
          useExamination().mapStudyList(consents);
        }
      },
      { immediate: true }
    );

    return {
      isLoading,
      studyList
    };
  }
});
</script>
