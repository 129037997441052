<template>
  <div class="examination-wrapper" :class="{ invisible: isLoading }">
    <template v-if="studyList.length !== 0">
      <template v-for="(item, index) in studyList" :key="`study--key-${index}`">
        <div class="examination">
          <div class="examination__info">
            <span class="bold">{{ item.date }}</span>
            <span>|</span>
            <span class="bold">{{ item.desc }}</span>
          </div>
          <div
            v-if="showPrint"
            class="examination__print-button"
            @click="print"
          >
            {{ $t('base.print') }}
          </div>
        </div>
        <FhirComponentsRouter :resource="item.resource" />
      </template>
    </template>

    <div
      v-if="isLoading === false && studyList.length === 0"
      class="text-center"
    >
      <p class="title-not-found">
        {{ $t('base.studyNotFound') }}
      </p>
      <BaseRedirectButton class="redirect-btn" />
    </div>
  </div>
</template>

<script lang="ts">
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import BaseRedirectButton from '@/components/Base/BaseRedirectButton.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
  name: 'CommonExamination',
  components: { BaseRedirectButton, FhirComponentsRouter },
  props: {
    isLoading: { required: false, default: false, type: Boolean },
    studyList: { required: false, default: () => [], type: Array },
    showPrint: { required: false, default: false }
  },
  setup() {
    const print = (): void => window.print();
    return { print };
  }
});
</script>

<style scoped lang="scss">
.examination-wrapper {
  position: relative;
  padding: 15px;
  border-radius: var(--main-radius);
  background-color: var(--light);
  /* pointer-events: none; */
  &.invisible {
    background-color: transparent;
  }
}
.examination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  &__info {
    display: flex;
    font-size: 1.25rem;
    span {
      &:nth-child(2) {
        margin: 0 6px;
      }
      &.bold {
        font-weight: 700;
      }
    }
  }
  &__print-button {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: var(--main-radius);
    text-transform: uppercase;
    border: 2px solid var(--main-color);
    color: var(--main-color);
    transition: 0.2s ease-in-out;
    &:hover {
      color: var(--light);
      background-color: var(--main-color);
    }
  }
}

.title-not-found {
  font-weight: 700;
  font-size: 1.225rem;
  text-align: center;
}
.text-center {
  text-align: center;
}
.redirect-btn {
  margin-top: 20px;
}
</style>
