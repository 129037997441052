
import { computed, ref, onMounted, defineComponent } from 'vue';
import useConsent from '@/composables/useConsent';
import useExamination from '@/components/Commons/Examination/useExamination';
import CommonExamination from '@/components/Commons/Examination/CommonExamination.vue';

export default defineComponent({
  name: 'ExaminationFromShare',
  components: {
    CommonExamination
  },
  setup() {
    const isLoading = ref<boolean>(true);
    const studyList = computed(() => useExamination().getStudyList());

    onMounted(async () => {
      await useExamination().loadStudyShared();
      const studyListById = useConsent().getListConsentsSharedForUser();
      useExamination().mapStudyList(studyListById);

      isLoading.value = false;
    });

    return {
      isLoading,
      studyList
    };
  }
});
