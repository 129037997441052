import { reactive } from 'vue';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import { getDateFromResource } from '@fhir/pixel-commons-js/src/index';
import { getCurrentIdFromRoute } from '@/utils/utils';
import useConsent from '@/composables/useConsent';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import i18n from '@/lang';

interface StateI {
  studyList: any[];
}

const state: StateI = reactive({
  studyList: []
});

export default function useExamination(): {
  routeId: () => string | null;
  mapStudyList: (list: any) => void;
  loadStudyShared: () => Promise<void>;
  getStudyList: () => any[];
} {
  const getStudyList = () => state.studyList;
  const routeId = () => getCurrentIdFromRoute() ?? null;

  const mapStudyList = (list: []) => {
    state.studyList = [];
    list.forEach((data: any) => {
      if (data.resource) {
        state.studyList.push({
          resource: data.resource,
          desc: getDescriptionFromResource(data.resource) ?? '',
          date: getDateFromResource(data.resource)
            ? getDateFromResource(data.resource)?.slice(0, 10)
            : ''
        });
      }
    });
  };

  const loadStudyShared = async () => {
    if (routeId()) {
      await useConsent().loadFhirResourcesByConsentId(routeId());
    } else {
      await useNotification().addNotification(
        NotificationType.ERROR,
        '',
        i18n.global.t('notifications.cantLoadData')
      );
    }
  };

  return {
    getStudyList,
    mapStudyList,
    routeId,
    loadStudyShared
  };
}
