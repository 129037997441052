
import { computed, ref, onMounted, defineComponent, watch } from 'vue';
import CommonExamination from '@/components/Commons/Examination/CommonExamination.vue';
import router from '@/router';
import useResource from '@/composables/useResource';
import useExamination from '@/components/Commons/Examination/useExamination';

export default defineComponent({
  name: 'ExaminationFromGeneratePdfForAdmin',
  components: {
    CommonExamination
  },
  setup() {
    const isLoading = ref<boolean>(true);
    const resourceType = router.currentRoute.value?.params?.resourceType?.toString();
    const resourceId = router.currentRoute.value?.params?.resourceId?.toString();
    const studyList = computed(() => useExamination().getStudyList());

    onMounted(async () => {
      if (resourceType && resourceId) {
        const resource = await useResource().loadResourceByTypeAndId(
          resourceType,
          resourceId
        );
        useExamination().mapStudyList([{ resource: resource }]);
      }
      isLoading.value = false;
    });
    return {
      isLoading,
      studyList
    };
  }
});
