
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import BaseRedirectButton from '@/components/Base/BaseRedirectButton.vue';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
  name: 'CommonExamination',
  components: { BaseRedirectButton, FhirComponentsRouter },
  props: {
    isLoading: { required: false, default: false, type: Boolean },
    studyList: { required: false, default: () => [], type: Array },
    showPrint: { required: false, default: false }
  },
  setup() {
    const print = (): void => window.print();
    return { print };
  }
});
